<template>
<div>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card class="pa-5">
          <div class="text-h4 text-center">Add People</div>
          <v-form ref="form"
                v-model="valid"
                lazy-validation
            >
            <v-container>
              <v-row class="justify-center">
                <v-col cols="12" lg="6">
                  <v-alert type="error" v-if="addErrResp">{{addErrResp}}</v-alert>
                  <v-text-field
                    v-model="formData.name"
                    label="Name"
                    type="text"
                    required
                    :rules="rules.nameRules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="12" lg="6">
                  <v-text-field v-model="formData.contact_number" label="Contact No" type="number"></v-text-field>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="12" lg="6">
                  <v-text-field v-model="formData.address" label="Address" type="text"></v-text-field>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="12" lg="6">
                  <v-textarea v-model="formData.description" label="Description" rows="3" type="text"></v-textarea>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="12" lg="6" class="text-center">
                  <v-btn color="primary" class="px-10" @click="addPeople()" :disabled="addLoading">Add</v-btn><br>
                   <v-progress-circular
                      indeterminate
                      color="primary"
                      class="mt-5"
                      v-if="addLoading"
                    ></v-progress-circular>
                </v-col>
              </v-row>  
            </v-container>
          </v-form>
        </v-card>  
      </v-col>
    </v-row>
  </v-container>
  </div>
</template>

<script>
import {create_people} from "@api/people"
export default {
  data(){
    return {
      valid:true,
      formData:{
        name:"",
        contact_number:"",
        address:"",
        description:"",
      },
      rules:{
        nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 10) || 'Name must be less than 10 characters',
        ],
        emailRules: [
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
      },
      addErrResp:'',
      addLoading:false,
    }
  },
  methods:{
    addPeople(){
      this.addLoading=true;
      this.addErrResp=''
      create_people(this.formData).then(resp=>{
        this.addLoading=false;
        if(resp.status==='Success'){
          this.$toastr.s(resp.message);
          this.$refs.form.reset();
          this.$router.push('/people');
        }else{
          this.addErrResp=resp.errors;
        }
      });
    }
  }
}
</script>

<style>

</style>